
// Set password page is to display and validate password form to update new password.

import { defineComponent, reactive, toRefs, ref } from "vue";
import FormGenerator from "@/components/forms/form-generator.vue"; // @ is an alias to /src
import { requiredValidator, passwordValidator } from "../../validators";
import ApiResources from "../../rest-resource";

export default defineComponent({
  name: "SetPassword",
  components: {
    FormGenerator,
  },
  setup() {
    const validatedForm: any = ref({});
    const state = reactive({
      schema: [
        {
          fieldType: "InputField",
          placeholder: "Password*",
          label: "",
          name: "password",
          type: "password",
          value: "",
          class: "form-group mt-3 mb-4",
          defaultValue: "",
          validators: [
            { validator: requiredValidator, message: "Password is required" },
            {
              validator: passwordValidator,
              message:
                "Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters",
            },
          ],
        },
        {
          fieldType: "InputField",
          placeholder: "Confirm Password*",
          label: "",
          name: "confirmPassword",
          type: "password",
          value: "",
          class: "form-group mb-2",
          defaultValue: "",
          validators: [
            {
              validator: requiredValidator,
              message: "Confirm Password is required",
            },
          ],
        },
      ],
      submitButton: {
        class: "btn submit-btn float-center",
        value: "Submit",
        loading: "Submitting...",
      },
    });
    return { ...toRefs(state), validatedForm };
  },
  methods: {
    async validForm(data: any) {
      try {
        const proxy1 = new Proxy(data, {});
        const dataValues = JSON.parse(JSON.stringify(proxy1));
        dataValues.forEach((element: any) => {
          this.validatedForm[element.name] = element.value;
        });
        if (this.validatedForm.password == this.validatedForm.confirmPassword) {
          let resourceEndPoint =
            ApiResources.AuthResource.wrap("/set-password");
          await resourceEndPoint.patch({
            password: this.validatedForm.password,
            token: this.$route.params.token,
          });
          this.displayMessage("Password updated successfully", "white");
          this.callRestForm();
          setTimeout(() => {
            this.$router.push("/login");
          }, 2000);
        } else {
          this.callRestForm(false);
          this.displayMessage(
            "Password and Confirm Password must be same",
            "warning"
          );
        }
      } catch (err: any) {
        this.callRestForm(false);
        this.displayMessage(err.response.data.message, "danger");
      }
    },
    callRestForm(reset = true) {
      (this.$refs["generatedform"] as any).resetForm(reset);
    },
  },
});
